<template>
  <SubPageWrapper
    data-shortcut-table
    width="full"
  >
    <CreateCollectionModal :id="modalsId.CREATE_COLLECTION" />

    <template #loader>
      <SlOverlay :show="isProcessing" />
    </template>
    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="collectionTabs"
        collapsable
      />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb @click="handleBack">
        {{ $t('Main.Ui.tabInventory:at') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('EditCollections.Ui.:Caption') }}
      </SlBreadcrumb>
    </template>
    <template #actions>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEdited"
        class="grey-80"
        @click="handleDiscard"
      >
        {{ $t('Web.Collections.Discard') }}
      </SlButton>
      <SlButton
        :disabled="!isEdited"
        @click="handleSave"
      >
        {{ $t('Web.Collections.SaveChanges') }}
      </SlButton>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <SlSearchInput
          :value="search"
          class="w-280"
          @submit="handleSearch"
        />
        <div class="subpage__actions-group" />
        <SlSelectionInfo
          v-if="isActionsVisible"
          @shortcut:delete="handleDelete"
        >
          <template
            v-if="selectedRowsCount"
            #description
          >
            {{ $t('Web.Selection', { 1: selectedRowsCount }) }}
          </template>
          <template #actions>
            <SlButton
              variant="text"
              color="grey"
              @click="resetSelected"
            >
              <template #prepend>
                <icon
                  class="fill-off size-20 color-grey-80"
                  data="@icons/close.svg"
                />
              </template>
              {{ $t('Web.Deselect') }}
            </SlButton>
            <SlButton
              variant="primary"
              color="accent-red"
              @click="handleDelete"
            >
              <template #prepend>
                <icon
                  data="@icons/trash.svg"
                  class="fill-off size-16 color-white-stroke"
                />
              </template>
              {{ $t('EditCollections.Ui.butRemoveContainer') }}
            </SlButton>
          </template>
        </SlSelectionInfo>
        <SlButton
          v-else
          variant="secondary"
          color="grey"
          class="grey-80"
          @click="showCreateModal"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="fill-off size-16 color-grey-80"
            />
          </template>
          {{ createBtnLabel }}
        </SlButton>
      </div>
      <div class="subpage__table-wrapper">
        <SlTabContent
          v-for="tab in collectionTabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <div class="subpage__table">
            <SlTable
              :id="tab.value"
              :ref="tab.value"
              :headers="tableHeaders"
              :rows="tableRows"
              :unique-key="tab.uniqueKey"
              header-unique-key="key"
              header-name-key="name"
              header-type-key="type"
              :hidden-columns-keys="['id', checkboxKey]"
              :max-height="1000"
              :value-parser="parseValue"
              :loading="loading"
              :inner-no-data="!!search"
              :sort-by="colClass"
              :sort-order="sortOrder"
              :total-rows-count="totalRowsCount"
              :current-page="currentPage"
              :per-page="perPage"
              api-sortable
              selectable
              pagination
              highlight-row
              @page-change="handlePaginationChange"
              @per-page-change="handlePerPageChange"
              @sort="handleSort"
              @contextmenu:clipboard="handleContextMenu"
            >
              <template #header-checkbox>
                <SlCheckbox
                  :value="headerCheckboxValue"
                  :indeterminate="isIndeterminate"
                  @change="handleSelectRowsAll"
                />
              </template>

              <template #row-checkbox="{ rowId, row }">
                <SlCheckbox
                  :value="isRowSelected(row)"
                  :disabled="isRowCheckboxDisabled(row)"
                  @change="(val) => handleSelectRows(val, rowId)"
                />
              </template>

              <template #loadWeight="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #loadVolume="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #palletLoad="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #loadQty="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #orderingCost="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #maxContainers="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>

              <template #minWeight="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #minVolume="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #minQty="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #maxQty="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #minCost="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #maxCost="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="9"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>

              <template #type="slotScope">
                <div class="sl-table__cell-text">
                  {{ getTypeValue(slotScope.cellValue) }}
                </div>
              </template>

              <template #loader>
                <SlOverlay :show="loading" />
              </template>

              <template #no-data>
                <SlNoData>
                  <template #image>
                    <icon
                      :data="noDataMeta.icon"
                      :class="{
                        'illustration-md': !search,
                        'size-48': search,
                        'fill-off': search
                      }"
                    />
                  </template>
                  <template #title>
                    {{ noDataMeta.title }}
                  </template>
                  <template #text>
                    {{ noDataMeta.text }}
                  </template>
                </SlNoData>
              </template>
            </SlTable>
          </div>
        </SlTabContent>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateCollectionModal from '@/components/Modals/Collections/CreateCollectionModal.vue';
import { modal } from '@/mixins/modal';
import { tableConfig } from '@/mixins/tableConfig';
import { shortcut } from '@/mixins/shortcut';
import {
  containerItemTypes,
  containersConfig,
  containerTypes
} from '@/config/report/inventoryReport/collections.config';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';
import { getTooltip } from '@/helpers/shared/tooltip';
import { checkboxStates } from '@/config/shared/slTable.config';
import statusCodes from '@/config/utils/statusCodes.config';
import modalIds from '@/config/shared/modalsId.config';

export default {
  name: 'Collections',
  components: {
    CreateCollectionModal
  },
  mixins: [modal, tableConfig, shortcut],
  data() {
    return {
      modalsId,
      typeMap: {
        [containerItemTypes.ORDINARY]: this.$t('Main.CreationType.Import'),
        [containerItemTypes.CUSTOM]: this.$t('Main.CreationType.Custom')
      },
      loading: false,
      isRedirect: false,
      isProcessing: false,
      checkboxKey: 'enabled',
      getTooltip
    };
  },
  computed: {
    ...mapState({
      [containerTypes.CONTAINER]: state => state.inventoryReport.collections[containerTypes.CONTAINER],
      [containerTypes.GROUP]: state => state.inventoryReport.collections[containerTypes.GROUP],
      [containerTypes.MANUFACTURE_GROUP]: state => state.inventoryReport.collections[containerTypes.MANUFACTURE_GROUP],
      tab: state => state.inventoryReport.collections.tab
    }),
    ...mapGetters({
      getExistingIds: 'inventoryReport/collections/getExistingIds',
      getCreateData: 'inventoryReport/collections/getCreateData',
      isEdited: 'inventoryReport/collections/isEdited'
    }),
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    collectionTabs() {
      return containersConfig(this);
    },
    tableConfig() {
      return this[this.tabModel].tableConfig;
    },
    isContainers() {
      return this.tabModel === containerTypes.CONTAINER;
    },
    table() {
      return this[this.tabModel].table || {};
    },
    tableHeaders() {
      return toArray(this.table.headers);
    },
    tableRows() {
      return toArray(this.table.rows);
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    checkboxHeader() {
      return this.table.metadata?.checkbox;
    },
    selectedRowsCount() {
      return this.table.metadata?.selectedRowsCount;
    },
    isIndeterminate() {
      return this.checkboxHeader === checkboxStates.MIXED;
    },
    headerCheckboxValue() {
      return this.checkboxHeader !== checkboxStates.UNCHECKED;
    },
    isActionsVisible() {
      return !this.loading
        && (this.headerCheckboxValue || this.selectedRowsCount > 0)
        && this.tableRows.length;
    },
    createBtnLabel() {
      if (this.isContainers) {
        return this.$t('CreateContainer.Ui.:Caption');
      }

      return this.$t('CreateGroup.Ui.:Caption');
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: this.$t('Web.NoData.PressToAddText', {
          1: this.createBtnLabel
        })
      };
    }
  },
  watch: {
    tabModel() {
      if (!this.tableHeaders.length) {
        this.fetchTableByType();
      }
    },
    isEdited() {
      preventTabClose(true);
    }
  },
  beforeMount() {
    this.fetchEntityByType();
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchEntityByType: 'inventoryReport/collections/fetchEntityByType',
      selectRow: 'inventoryReport/collections/selectRow',
      submitCell: 'inventoryReport/collections/submitCell',
      createEntity: 'inventoryReport/collections/createEntity',
      deleteEntities: 'inventoryReport/collections/deleteEntities',
      saveOverrides: 'inventoryReport/collections/saveOverrides',
      discardOverrides: 'inventoryReport/collections/discardOverrides',
      updateOverrides: 'inventoryReport/collections/updateOverrides',
      resetState: 'inventoryReport/collections/resetState',
      setTab: 'inventoryReport/collections/setTab',
      updateTableConfig: 'inventoryReport/collections/updateTableConfig'
    }),
    async fetchTableByType() {
      try {
        this.loading = true;

        await this.fetchEntityByType();
      } finally {
        this.loading = false;
      }
    },
    // route leave
    handleBack() {
      this.$router.push({
        name: routeNames.INVENTORY
      });
    },
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: async() => {
          await this.discardOverrides();

          this.isRedirect = true;

          next();
        },
        confirmCallback: async() => {
          await this.handleSave();

          this.isRedirect = true;

          next();
        }
      });
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchTableByType();
    },
    // table configuration
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    getInputValue(scope) {
      return this.checkCellValue(scope.cellValue?.val) ?? this.checkCellValue(scope.cellValue?.auto) ?? '';
    },
    getTypeValue(value) {
      return this.typeMap[value.val];
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val || val === 0) {
        return 'over';
      }

      return '';
    },
    formatCellValue(val) {
      if (val === null) {
        return val;
      }

      return +val;
    },
    handleCellSubmit({ val, slotScope }) {
      this.submitCell({
        value: this.formatCellValue(val),
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey
      });

      this.updateOverrides({
        id: slotScope.rowId,
        [slotScope.cellKey]: this.formatCellValue(val)
      });
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchTableByType();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTableByType();
    },
    async handleSort({ callback, isResort }) {
      const { key, order } = callback();

      this.oldSortOrder = this.sortOrder;
      this.oldCollClass = this.colClass;

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: this.tabModel
          }
        });
      } catch (e) {
        this.sortOrder = this.oldSortOrder;
        this.colClass = this.oldCollClass;

        // todo: ask teammates
        if (isResort || e.status !== statusCodes.FORBIDDEN) {
          return;
        }

        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
          text: this.$t('Web.Modals.UnsavedChangesSort'),
          icon: 'style_warning',
          discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
          confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
          discardCallback: async() => {
            await this.discardOverrides();

            this.handleSort({
              callback,
              isResort: true
            });
          },
          confirmCallback: async() => {
            await this.handleSave();

            this.handleSort({
              callback,
              isResort: true
            });
          }
        });
      } finally {
        this.loading = false;
      }
    },
    // rows select
    isRowSelected(row) {
      return row[this.checkboxKey]?.val;
    },
    isRowCheckboxDisabled({ type }) {
      return type?.val !== containerItemTypes.CUSTOM;
    },
    resetSelected() {
      this.$refs[this.tabModel][0]?.resetSelected();

      this.updateOverrides({
        [this.checkboxKey]: false
      });
    },
    handleSelectRowsAll(checked) {
      this.updateOverrides({
        [this.checkboxKey]: checked
      });
    },
    handleSelectRows(checked, id) {
      this.selectRow({
        value: checked,
        rowId: id,
        cellKey: this.checkboxKey
      });

      this.updateOverrides({
        id,
        [this.checkboxKey]: checked
      });
    },
    // create/delete
    showCreateModal() {
      this.showModal(modalsId.CREATE_COLLECTION, {
        inputData: this.getCreateData(this.tabModel, this),
        existingIds: this.getExistingIds(this.tabModel),
        type: this.tabModel,
        createCallback: this.handleCreateItem
      });
    },
    handleDelete() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', {
          1: this.$t('Web.Pluralization.Item.val', {
            p1: this.$tc('Web.Pluralization.Item.p1', this.selectedRowsCount, { 1: this.selectedRowsCount })
          })
        }),
        text: this.$t('Web.Modals.Delete.DescriptionBulk', {
          1: this.$tc('Web.Pluralization.ItThem', this.selectedRowsCount)
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: async() => {
          await this.deleteEntities();

          this.resetSelected();
        }
      });
    },
    async handleCreateItem(item) {
      await this.createEntity(item);

      this.resetSelected();
    },
    // save/discard
    async handleSave() {
      try {
        this.isProcessing = true;

        await this.saveOverrides();

        this.$notify({
          type: 'success',
          title: this.$t('Common.Save'),
          text: this.$t('Web.Notification.TitleSavedSuccess')
        });
      } finally {
        this.isProcessing = false;
      }
    },
    async handleDiscard() {
      if (this.isEdited) {
        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
          text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
          icon: 'style_save_double',
          confirmText: this.$t('Web.Collections.Discard'),
          confirmCallback: this.discardChanges
        });
      }
    },
    async discardChanges() {
      try {
        this.isProcessing = true;

        await this.discardOverrides();
      } finally {
        this.isProcessing = false;
      }
    },
    checkCellValue(value) {
      return (value || value === 0) ? value : null;
    },
    handleContextMenu(callback) {
      callback({ updateCallback: this.handleCellSubmit });
    }
  }
};
</script>

<style lang="scss" scoped>
.subpage__table-wrapper {
  height: calc(100% - 52px);
}
</style>
