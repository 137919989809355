import { render, staticRenderFns } from "./CreateCollectionModal.vue?vue&type=template&id=6206daea&scoped=true&"
import script from "./CreateCollectionModal.vue?vue&type=script&lang=js&"
export * from "./CreateCollectionModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateCollectionModal.vue?vue&type=style&index=0&id=6206daea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6206daea",
  null
  
)

export default component.exports