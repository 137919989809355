<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    persistent
    @created="onCreated"
    @on-enter="handleCreate"
    @hide="onModalClose"
    @show="onModalShow"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content create-container"
    >
      <SlValidate
        v-slot="{ invalid }"
        :rules="idRules"
      >
        <SlInput
          v-model="newItem.identifier"
          :label="idLabelByType[type]"
          :is-invalid="invalid"
          required
        />
      </SlValidate>
      <SlValidate
        v-for="input in inputData"
        :key="input.key"
        v-slot="{ invalid }"
        :vid="input.key"
        :rules="`max:${maxLength}`"
        class="w-50"
      >
        <SlInput
          :key="inputKey"
          v-model="newItem[input.key]"
          :label="input.text"
          type="number"
          :is-invalid="invalid"
        />
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleCreate">
          {{ $t('EditCollections.Ui.butCreateContainer') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { containerTypes } from '@/config/report/inventoryReport/collections.config';

export default {
  name: 'CreateCollectionModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inputData: [],
      type: '',
      existingIds: [],
      createCallback: null,
      inputKey: false,
      isValid: false,
      newItem: {},
      maxLength: 9 // 10 todo rb after be fix inventory max value error
    };
  },
  computed: {
    titleByType() {
      return {
        [containerTypes.CONTAINER]: this.$t('Web.Collections.Containers.CreateTitle'),
        [containerTypes.GROUP]: this.$t('Web.Collections.Groups.CreateTitle'),
        [containerTypes.MANUFACTURE_GROUP]: this.$t('Web.Collections.ManufactureGroups.CreateTitle')
      };
    },
    idLabelByType() {
      return {
        [containerTypes.CONTAINER]: this.$t('CreateContainer.Ui.lbContainerId'),
        [containerTypes.GROUP]: this.$t('CreateGroup.Ui.lbGroupId'),
        [containerTypes.MANUFACTURE_GROUP]: this.$t('Web.Collections.ManufactureGroups.LabelId')
      };
    },
    modalTitle() {
      return this.titleByType[this.type];
    },
    isContainer() {
      return this.type === containerTypes.CONTAINER;
    },
    idRules() {
      return {
        required: true,
        'sl_not_includes': {
          values: this.existingIds,
          message: this.$t('Web.Error.AlreadyExist', {
            1: this.idLabelByType[this.type]
          })
        }
      };
    }
  },
  methods: {
    onModalShow() {
      this.inputData.forEach(item => {
        this.newItem[item.key] = item.default || '';
      });

      this.inputKey = true;
    },
    onModalClose() {
      this.inputData = [];
      this.type = '';
      this.existingIds = [];
      this.createCallback = null;
      this.newItem = {};
      this.isValid = false;
      this.inputKey = false;
    },
    async handleCreate() {
      this.isValid = await this.$refs.observer.validate();

      if (!this.isValid || !this.createCallback) {
        return;
      }

      try {
        await this.createCallback({
          ...this.newItem,
          identifier: this.newItem.identifier.trim()
        });

        this.hideModal(this.id);
      } catch (e) {
        const message = e?.message;

        if (!message) {
          return;
        }

        this.$notify({
          type: 'error',
          title: message
        });
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.create-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;

  .w-50 {
    width: calc(50% - 8px);
  }
}
</style>